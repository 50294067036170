import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactPlayer from "react-player";
import peer from "../../../services/peer";
import { useSocket } from "../../../context/SocketProvider";
import Header from "../../header";
import microphoneSlash from "./../../../assets/image/microphone_slash.svg";
import microphone from "./../../../assets/image/microphone.svg";
import userEvent from "@testing-library/user-event";
import endCall from "./../../../assets/image/end_call.svg";
import startRecord from "./../../../assets/image/record_start.svg";
import stopRecord from "./../../../assets/image/record_stop.svg";
import { useNavigate, useParams } from "react-router-dom";
import Transcription from "./../ExternalInterviewTranscription/ExternalInterviewTranscription";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const ExternalInterviewRoom = () => {
  const [remoteSocketId, setRemoteSocketId] = useState("");
  const [myStream, setMyStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const navigate = useNavigate();
  const [isCalling, setIsCalling] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);
  const [muted, setMuted] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const email = localStorage.getItem("email");
  const userTypeValue = localStorage.getItem("userType");
  const staffFirstName = localStorage.getItem("staff_name");
  const userFirstName = localStorage.getItem("user_name");
  const socket = useSocket();
  const [userType, setUserType] = useState("");
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const mediaChunks = useRef([]);
  const room_id = localStorage.getItem("resourceful_video_call_room");
  const [recordSocket, setRecordSocket] = useState(null);
  const [isRecordSocketReady, setIsRecordSocketReady] = useState(false);
  const [chat, setChat] = useState([]);
  const [microphoneData, setMicrophone] = useState(false);
  const [writeAi, setWriteAi] = useState(false);
  const [finalConnentDetail, setFinalConnentDetail] = useState('');
  const [currentChat, setCurrentChat] = useState([]);
  const [message, setMessage] = React.useState("");
  
  const finalTranscriptTimeOut = 5000;
  const inactivityTimerRef = useRef(null);

  const commands = [
    {
      command: "reset",
      callback: () => resetTranscript(),
    },
    {
      command: "shut up",
      callback: () => setMessage("I wasn't talking."),
    },
    {
      command: "Hello",
      callback: () => setMessage("Hi there!"),
    },
  ];

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });
  const listenContinuously = () => {
    // if (recordingPermission && !pauseRecord) {
      SpeechRecognition.startListening({
        continuous: true,
        language: "en",
      });
      setMicrophone(true);
    // } else {
    //   alert("Your interview will begin after permission to screen record.");
    // }
  };

  function audioOff() {
    SpeechRecognition.stopListening();
    setMicrophone(false);
  }

  const finalTimers = useCallback(() => {
    console.log('finalConnentDetail', finalConnentDetail);
    // setMicrophone(false);
    const datetime = getCurrentTime();
    const time = getTimeOnly(datetime);
    const newData = Array.isArray(chat)
      ? [
          ...chat,
          {
            logo: userTypeValue === 'staff' ? 'NI' : 'AS',
            firstName: userTypeValue === 'staff' ? staffFirstName : userFirstName,
            type: userTypeValue === 'staff' ? 'staff' : 'candidate',
            message: finalConnentDetail,
            createdAt: time,
            createdDateTimeAt: datetime,
          },
        ]
      : [finalConnentDetail];
      
    setChat(newData);
    setCurrentChat(newData);
    socket.send(
      JSON.stringify({
        type: "send:transcript",
        to: remoteSocketId,
        transcript: finalConnentDetail,
      })
    );
    resetTranscript();
  }, [finalConnentDetail]);

  const handleSocketTranscript = useCallback((response) => {
    const datetime = getCurrentTime();
    const time = getTimeOnly(datetime);

    const newMessage = {
      logo: userTypeValue === 'staff' ? 'AS' : 'NI',
      firstName: userTypeValue === 'staff' ? userFirstName : staffFirstName,
      type: userTypeValue === 'staff' ? 'candidate' : 'staff',
      message: response.transcript,
      createdAt: time,
      createdDateTimeAt: datetime,
    };
  
    setChat((prevChat) => {
      const updatedChat = Array.isArray(prevChat) ? [...prevChat, newMessage] : [newMessage];
      return updatedChat;
    });
  
    setCurrentChat((prevChat) => {
      const updatedCurrentChat = Array.isArray(prevChat) ? [...prevChat, newMessage] : [newMessage];
      return updatedCurrentChat;
    });
  }, [finalTimers]);

  const resetFinalTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(() => {
      finalTimers();
    }, finalTranscriptTimeOut);
  }, [finalTimers]);

  useEffect(() => {
    if (finalConnentDetail !== "") {
      resetFinalTimer();
    }

    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [resetFinalTimer]);


  useEffect(() => {
    if (finalTranscript !== "") {
      setFinalConnentDetail(finalTranscript);
      resetFinalTimer();
    }
  }, [finalTranscript]);

  const getCurrentTime = () => {
    const date = new Date();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Check if the time is in 12-hour format
    let timeFormat = "24";
    let meridiem = "";
    if (hours > 12) {
      hours -= 12;
      meridiem = "PM";
      timeFormat = "12";
    } else if (hours === 0) {
      hours = 12;
      meridiem = "AM";
      timeFormat = "12";
    } else {
      meridiem = "AM";
    }

    // Add leading zeros if necessary
    hours = hours < 10 ? `0${hours}` : hours;
    const formattedTime = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${formattedTime} ${
      timeFormat === "12" ? meridiem : "AM"
    }`;

  };

  const getTimeOnly = (dateTimeStr) => {
    return dateTimeStr.split(" ")[1] + " " + dateTimeStr.split(" ")[2];
  };

  useEffect(() => {
    const updateTimeStamp = async (currentChat) => {
      let question_timestamp;
      let answer_timestamp = "";
      let questionID;
      for (let i = chat.length - 1; i >= 0; i--) {
        const message = chat[i];
        if (message.type === "user") {
          answer_timestamp = message.createdDateTimeAt;
        }

        if (message.type === "ai") {
          question_timestamp = message.createdDateTimeAt;
          questionID = message.questionID;
        }

        if (questionID && question_timestamp) {
          break;
        }
      }
      if (questionID && question_timestamp) {
        let data = {
          question_id: questionID,
          question_timestamp: question_timestamp,
          answer_timestamp: answer_timestamp,
          interview_type:"AI"
        };

        // const JsonData = {
        //   url: endPoint.UPDATE_TIMESTAMP,
        //   method: "post",
        //   data: JSON.stringify(data),
        // };
        // var apiResponse = await apiCall(JsonData);
        // return;
      }
    };
    updateTimeStamp(currentChat);
  }, [currentChat]);

  useEffect(() => {
    console.log("recordSocket", recordSocket);
    if (!recordSocket) {
      var recordSockeConnection = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/video_call_recording/${room_id}/`
      );
      setRecordSocket(recordSockeConnection);
    }
  }, [recordSocket, isRecordSocketReady]);

  const [delay, setDelay] = useState(0);
  const stopStreamTracks = (stream) => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleRecording = async () => {
    if (recording) {
      recorder.stop();
      setRecording(false);
    } else {
      // const combinedStream = new MediaStream();
      if (!recordSocket) {
        setIsRecordSocketReady(!isRecordSocketReady);
      }
      var displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      const audioContext = new AudioContext();
      const audioDestination = audioContext.createMediaStreamDestination();

      if (myStream) {
        const myAudioTracks = myStream.getAudioTracks();

        myAudioTracks.forEach((track) => {
          const source = audioContext.createMediaStreamSource(
            new MediaStream([track])
          );
          source.connect(audioDestination);
        });
      }

      if (remoteStream) {
        const remoteAudioTracks = remoteStream.getAudioTracks();
        remoteAudioTracks.forEach((track) => {
          const source = audioContext.createMediaStreamSource(
            new MediaStream([track])
          );
          source.connect(audioDestination);
        });
      }
      const combinedStream = new MediaStream([
        ...displayStream.getVideoTracks(),
        ...audioDestination.stream.getAudioTracks(),
      ]);

      const newRecorder = new MediaRecorder(combinedStream);

      newRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log("event.data", event.data);
          mediaChunks.current.push(event.data);
          recordSocket.send(event.data);
        }
      };

      newRecorder.onstop = () => {
        const blob = new Blob(mediaChunks.current, { type: "video/webm" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "recording.webm";
        a.click();
        window.URL.revokeObjectURL(url);
        stopStreamTracks(displayStream);
        // displayStream = null;
      };

      newRecorder.start(5000);
      setRecorder(newRecorder);
      setRecording(true);
    }
  };

  if (delay === 0) {
    if (email === "19196dd3-b283-4803-bd8f-e45e5a58865b") {
      setUserType("staff");
      setDelay(250);
    } else {
      setUserType("candidate");
      setDelay(500);
    }
  }

  useEffect(() => {
    if (peer.peer) {
      peer.peer.onicecandidate = (event) => {
        console.log("event.candidate", event.candidate);
        if (event.candidate) {
          socket.send(
            JSON.stringify({
              type: "ice:candidate",
              to: remoteSocketId,
              candidate: event.candidate,
            })
          );
        }
      };
    }
  }, [remoteSocketId, socket]);

  const handleUserJoined = useCallback(({ email, id }) => {
    console.log(`Email ${email} joined room ${id}`);
    setRemoteSocketId(id);
    setIsCalling(true);
    listenContinuously();
  }, []);

  function checkEmail(socket_email, socket_id) {
    if (socket_email === email) {
      localStorage.setItem("my_socket_id", socket_id);
      return true;
    }
    return false;
  }

  const handleIncommingCall = useCallback(
    async ({ from, offer }) => {
      setRemoteSocketId(from);
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
        video: true,
      });
      setMyStream(stream);
      const ans = await peer.getAnswer(offer);
      socket.send(
        JSON.stringify({
          type: "call:accepted",
          to: from,
          ans,
        })
      );
      setIsAnswering(true);
      // sendStreams(stream);
      console.log("52 myStream", myStream);
      console.log("53 remoteSocketID", remoteSocketId);
    },
    [socket]
  );

  const handleCallUser = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
      },
      video: true,
    });
    console.log("remoteSocketId", remoteSocketId);
    if (remoteSocketId) {
      const offer = await peer.getOffer();
      socket.send(
        JSON.stringify({
          type: "user:call",
          to: remoteSocketId,
          offer,
        })
      );
      setMyStream(stream);
      // sendStreams(stream);
      console.log("73 myStream", myStream);
    }
  }, [remoteSocketId]);

  // const sendStreams = useCallback(async () => {
  useEffect(() => {
    function sendStream(myStream) {
      if (myStream) {
        for (const track of myStream.getTracks()) {
          peer.peer.addTrack(track, myStream);
        }
      }
    }
    const timer = setTimeout(() => {
      console.log("Executing code after 1 second delay due to call acceptance");
      console.log("myStream", myStream);
      if (myStream) {
        sendStream(myStream);
      } else {
        console.log("No Stream");
      }
      // }
    }, 1000);
    return () => clearTimeout(timer);
  }, [isCallAccepted]);

  const handleCallAccepted = useCallback(async ({ from, ans }) => {
    try {
      // if (peer.peer.signalingState !== "stable") {
      //   //HAVE-LOCAL-OFFER
      //   console.log("Call accepted but already in stable state");
      //   return;
      // }
      console.log(
        "handleCallAccepted => peer.peer.signalingState : ",
        peer.peer.signalingState
      );
      await peer.setLocalDescription(ans);
      console.log("CallAccepted myStream:", myStream);
      console.log("CalslAccepted remoteStream:", remoteStream);
      console.log("Call Accepted and local description set!");
      setIsCallAccepted(true);
    } catch (error) {
      console.error("Error setting local description:", error);
    }
  }, []);

  const handleNegoNeeded = useCallback(async () => {
    const offer = await peer.getOffer();
    // delay;/
    console.log("delay", delay);
    // setTimeout(() => {
    //   socket.send(
    //     JSON.stringify({
    //       type: "peer:nego:needed",
    //       to: remoteSocketId,
    //       offer,
    //     })
    //   );
    // }, delay);

    socket.send(
      JSON.stringify({
        type: "peer:nego:needed",
        to: remoteSocketId,
        offer,
      })
    );
  }, [remoteSocketId, socket]);

  useEffect(() => {
    peer.peer.addEventListener("negotiationneeded", handleNegoNeeded);
    return () => {
      peer.peer.removeEventListener("negotiationneeded", handleNegoNeeded);
    };
  }, [handleNegoNeeded]);

  useEffect(() => {
    peer.peer.addEventListener("track", (ev) => {
      const currentRemoteStream = ev.streams;
      setRemoteStream(currentRemoteStream[0]);
    });
  }, []);

  const handleNegoNeedIncomming = useCallback(
    async ({ from, offer }) => {
      try {
        // while (peer.peer.signalingState !== "stable") {
        //   console.log("Connection not stable, waiting...");
        //   await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed
        // }
        console.log("Incoming offer:", offer);
        console.log("@@from", from);

        const ans = await peer.getAnswer(offer);
        socket.send(
          JSON.stringify({
            type: "peer:nego:done",
            to: from,
            ans,
          })
        );
      } catch (error) {
        console.error("Error handling incoming negotiation:");
        console.log("peer.peer.signalingState", peer.peer.signalingState);
        console.log("193##from", from);

        console.error("Error handling incoming negotiation:", error);
      }
    },
    [socket]
  );

  const handleNegoNeedFinal = useCallback(async ({ from, ans }) => {
    try {
      // if (peer.peer.signalingState !== "stable") {
      //   console.log("Connection not stable, skipping answer setting");
      //   return;
      // }
      console.log("Incoming answer:", ans);
      console.log("@@from", from);
      console.log("peer.peer.signalingState", peer.peer.signalingState);
      await peer.setLocalDescription(ans);
    } catch (error) {
      console.error("Error setting final negotiation:", error);
      console.log("peer.peer.signalingState", peer.peer.signalingState);
      console.log("from", from);
      console.error("Error setting final negotiation:", error);
    }
  }, []);
  const handleEndCallSocket = useCallback(async () => {
    try {
      stopStreamTracks(myStream);
      stopStreamTracks(remoteStream);
      // stopStreamTracks(displayStream);

      setMyStream(null);
      setRemoteStream(null);
      setRemoteSocketId("");
      if (recording) {
        recorder.stop();
        setRecording(false);
        recordSocket.close();
      }
      socket.close();
      // if (peer) {
      //   console.log("closing peer connection");
      //   peer.close();
      //   peer = null;
      // }
      navigate("/thank-you");
    } catch (error) {
      console.error("Error end:call", error);
    }
  }, []);
  var countValue = 0; 
  useEffect(() => {
    if (socket) {
      socket.onmessage = (e) => {
        const response = JSON.parse(e.data);
        const type = response.type;
        if (type === "user:joined") {
          const email = response?.email;
          const id = response?.id;
          var IsNewEmail = checkEmail(email, id);
          if (!IsNewEmail) {
            handleUserJoined({ email, id });
          }
        } else if (type === "incomming:call") {
          const offer = response?.offer;
          const from = response?.from;
          handleIncommingCall({ from, offer });
          console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "call:accepted") {
          const ans = response?.ans;
          const from = response?.from;
          handleCallAccepted({ from, ans });
          console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "peer:nego:needed") {
          const offer = response?.offer;
          const from = response?.from;
          handleNegoNeedIncomming({ from, offer });
          console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "peer:nego:final") {
          const ans = response?.ans;
          const from = response?.from;

          handleNegoNeedFinal({ from, ans });
          console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "ice:candidate") {
          console.log("272 Response,", response);
          const candidate = response?.candidate;
          const from = response?.from;
          peer
            .addIceCandidate(candidate)
            .catch((e) =>
              console.error("278 Error adding received ICE candidate", e)
            );


            // Assuming userTypeValue is assigned somewhere before this condition
            if (userTypeValue === 'staff' && countValue === 0) {
              console.log(userTypeValue);
              countValue = 1;
              handleRecording(); // Assuming handleRecording is a defined function
            }
          // handleNegoNeedFinal({ from, ans });
          console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "end:call") {
          // const from = response?.from;

          handleEndCallSocket();
          console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "send:transcript") {
          handleSocketTranscript(response);
        }
      };
    }
  }, [socket]); 

  useEffect(() => {
    handleCallUser();
  }, [remoteSocketId]);

  const toggleMuted = () => {
    // if (myStream) {
    //   myStream.getAudioTracks().forEach((track) => {
    //     console.log("track", track);
    //     track.enabled = !track.enabled;
    //   });
    // }
    setMuted(!muted);
  };

  const handleEndCallClick = (e) => {
    console.log(e);
    console.log("button clicked");
    // socket.close();
    socket.send(
      JSON.stringify({
        type: "end:call",
        to: remoteSocketId,
      })
    );
    if (recording) {
      recorder.stop();
      setRecording(false);
      recordSocket.close();
    }
    stopStreamTracks(myStream);
    stopStreamTracks(remoteStream);
    setMyStream(null);
    setRemoteStream(null);
    setRemoteSocketId("");
    socket.close();
    // if (peer) {
    //   console.log("closing peer connection");
    //   peer.close();
    //   peer = null;
    // }
    navigate("/thank-you");
  };

  return (
    <div>
      <Header />
      <div className='grid lg:grid-cols-4 md:grid-cols-12 antialiased p-2 sm:px-6 lg:px-8'>
          <div className='md:col-span-7 lg:col-span-3 col-span-12 relative h-full'>
              {remoteSocketId ? (
                <div className='relative h-full max-md:w-full'>
                <div className='absolute bottom-2 flex justify-left z-[9999]'>
                    <h4 className='text-[20px] text-left   text-white bg-black/[0.6] p-1'>{userTypeValue === 'staff' ? userFirstName : staffFirstName}</h4>
                  </div>
                  <div className='flex flex-col justify-center items-center'>
                
                    <div className='absolute bottom-2 flex justify-center  z-[9999]'>
                      {remoteStream && (
                        <>
                         {listening ? (
                            <button type='button' onClick={() => audioOff()}>
                              <span className='relative flex'>
                                <span className='animate-ping absolute inline-flex w-full h-full text-center rounded-full bg-blue opacity-75'></span>
                                <img
                                  src={microphone}
                                  className='w-[25px] h-[25px]'
                                  alt='Muted Microphone'
                                />
                              </span>
                            </button>
                          ) : (
                            <button type='button' onClick={listenContinuously}>
                              <img
                                src={microphoneSlash}
                                className='w-[25px] h-[25px]'
                                alt='Active Microphone'
                              />
                            </button>
                          )}
                        </>
                      )}
                      <div className='bg-gray-100/50 p-2 rounded-full w-[50px] h-[50px] ml-3 flex content-center justify-center'>
                        <button type='button' onClick={handleEndCallClick}>
                          <span className='relative flex'>
                            <img
                              src={endCall}
                              className='w-[25px] h-[25px]'
                              alt='Stop Screen Record'
                            />
                          </span>
                        </button>
                      </div>
                      <div className='bg-gray-100/50 p-2 rounded-full w-[50px] h-[50px] ml-3 flex content-center justify-center'>
                        <button onClick={handleRecording}>
                          <span className='relative flex'>
                            <img
                              src={startRecord}
                              className='w-[25px] h-[25px]'
                              alt='Stop Screen Record'
                            />
                          </span>
                        </button>
                      </div>
                      {/* <div>
                        <button onClick={handleRecording}>
                          <span className='relative flex'>
                            <img
                              src={startRecord}
                              className='w-[25px] h-[25px]'
                              alt='Stop Screen Record'
                            />
                          </span>
                        </button>
                      </div> */}
                    </div>

                    {remoteStream && (
                      <div className='absolute bottom-2 flex justify-right w-[140px] right-[15px] video-screen rounded-md  border-2 z-[9999] border-[#6baafa] '>
                        {myStream && (
                          <>
                          <ReactPlayer
                            className='mx-auto'
                            playing
                            url={myStream}
                            width='100%'
                            height='auto'
                            muted
                          />
                          <div className='absolute bottom-0 flex justify-left z-[9999]'>
                            <h4 className='text-[10px] text-left text-white bg-black/[0.6] p-1'>{userTypeValue === 'staff' ? staffFirstName : userFirstName}</h4>
                          </div>
                          </>
                          
                        )}
                      </div>
                    )}
                    <div className='w-full video-call'>
                      <ReactPlayer
                        className='mx-auto'
                        playing
                        url={remoteStream ? remoteStream : myStream}
                        width='100%'
                        height='auto'
                        muted
                        key={
                          remoteStream !== undefined && "id" in remoteStream
                            ? remoteStream?.id
                            : myStream?.id
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-full bg-gray-100 rounded-2xl">
                  <h4 className='text-[25px] text-center p-5'>No one in room</h4>
                </div>
              )}
          </div>
          <div className='md:col-span-5 lg:col-span-1 col-span-12 max-md:pt-[10px]'>
            <div className='m-0 p-0 w-full'>
              <div className='flex flex-col flex-auto h-full'>
                <p>

                {microphoneData}
                </p>
                <Transcription
                  setChat={setChat}
                  chat={chat}
                  microphone={microphoneData}
                  writeAi={writeAi}
                />
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default ExternalInterviewRoom;