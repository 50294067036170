import React, {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
const SocketContext = createContext(null);

export const useSocket = () => {
  const socket = useContext(SocketContext);
  return socket;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
export const SocketProvider = (props) => {
  const [socket, setSocket] = useState(null);
  const location = useLocation();
  const query = useQuery();
  console.log("location", location);
  useEffect(() => {
    const room_id = query.get("room");

    if (room_id) {
      // const ws = new WebSocket(
      //   `${process.env.REACT_APP_WEBSOCKET_URL}/video_call/${room_id}/`
      // );

      const ws = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/video_call/${room_id}/`
      );
      // const ws = new WebSocket(`wss://videocall.theresourceful.network/video_call/${room_id}/`);

      setSocket(ws);
    }
  }, [location.search]);

  // if (!socket) {
  //   return <div>Loading...</div>; // or some loading indicator
  // }

  //   const socket = useMemo(() => io("ws://localhost:8000/ws/call/"), []);
  // const socket = new WebSocket("wss://interview.fitai.me/test1/");
  // const socket = new WebSocket("ws://localhost:8000/test1/");

  //   const socket = useMemo(
  //     () =>
  //       io("ws://localhost:8000/ws/some_path/", {
  //         transports: ["websocket"],
  //       }),
  //     []
  //   );
  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
};
