import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../context/SocketProvider";
import ReactPlayer from "react-player";
import Header from "../../header";
const VideoCallLobbyScreen = ({}) => {
  const [email, setEmail] = useState("");
  const [room, setRoom] = useState("");
  const [myStream, setMyStream] = useState(null);
  // const [socket, setSocket] = useState(null);
  const userType = localStorage.getItem("userType");
  const socket = useSocket();
  // console.log("socket", socket);
  // useEffect(() => {
  //   if (socket === null) {
  //     const socketConnection = new WebSocket("ws://localhost:8000/test1/");
  //     setSocket(socketConnection);
  //   }
  // }, [socket]);
  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
          video: true,
        });
        setMyStream(stream);
      } catch (err) {
        console.error("Error accessing media devices.", err);
      }
    };
    getMediaStream();
  }, []);

  const navigate = useNavigate();
  const handleSubmitForm = useCallback(
    (e) => {
      console.log("e", e);
      e.preventDefault();

      //   socket.emit("room:join", { email, room });
      console.log("socket", socket);
      console.log("socket", socket?.readyState);
      console.log(email, room);

      if (socket && socket.readyState === WebSocket.OPEN) {
        const emailValue = localStorage.getItem("resourceful_video_call_id");
        const roomValue = localStorage.getItem("resourceful_video_call_room");
        localStorage.setItem("email", emailValue);
        socket.send(
          JSON.stringify({
            type: "room:join",
            email: emailValue,
            room: roomValue,
          })
        );
      }
    },
    [email, room, socket]
  );

  const handleJoinRoom = useCallback(
    (data) => {
      const { email, room } = data;
      myStream.getTracks().forEach((track) => {
        track.stop();
      });
      navigate(`/video-call-room/${room}`);
    },
    [navigate, myStream]
  );

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "room:join") {
          handleJoinRoom(data);
        }
      };
    }
  }, [socket, handleJoinRoom]);
  return (
    <div>
      <Header />
      <div className='text-center mx-auto max-w-[85rem] pt-5 h-[90vh] flex justify-center items-center'>
        <div className='relative w-full'>
          <div className='flex flex-col justify-center items-center'>
            <div className='absolute bottom-0 flex justify-center w-full pb-3'>
              <form className='w-full max-w-sm z-[9999]'>
                <button
                  type='button'
                  className='z-[9999] shadow bg-[#0f6af9] hover:bg-[#0f6af9] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded'
                  onClick={(e) => {
                    setEmail("abc@gmail.com");
                    setRoom("123");
                    handleSubmitForm(e);
                  }}
                >
                  Join Call
                </button>
              </form>
            </div>
            <div className='video-screen'>
              {myStream && (
                <video
                  className='mx-auto'
                  autoPlay
                  muted
                  ref={(videoElement) => {
                    if (videoElement) {
                      videoElement.srcObject = myStream;
                    }
                  }}
                  style={{
                    width: "100%",
                    height: "auto",
                    transform: "scaleX(-1)",
                  }}
                />
              )}
              {/* <ReactPlayer
                className='mx-auto'
                playing
                url={myStream}
                width='100%'
                height='auto'
                
                muted
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCallLobbyScreen;
