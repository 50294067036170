import React, { useEffect, useMemo, useState } from "react";
import logo from "../../../assets/image/logo.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { endPoint } from "../../../utils/endPoint";
import apiCall from "../../../utils/axiosCall";
import decryptMessage from "../../../utils/decrypt";
// Import style here
const VideoCallOtpScreen = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [time, setTime] = useState("");
  const [room_id, setRoomId] = useState("");
  const [uuid, setUuid] = useState("");
  const resourceful_video_call_id = localStorage.getItem(
    "resourceful_video_call_id"
  );

  useEffect(() => {
    const error = getQueryParam("error");
    if (error) {
      setError("please check your URL.");
    } else {
      getRoomDetail();
    }
  }, []);

  const getRoomDetail = async () => {
    const room_id_value = getQueryParam("room");
    const uuid_value = getQueryParam("id");
    setRoomId(room_id_value);
    setUuid(uuid_value);

    localStorage.setItem("resourceful_video_call_id", uuid_value);
    localStorage.setItem("resourceful_video_call_room", room_id_value);

    let data = {
      room_id: room_id_value,
      uuid: uuid_value,
    };

    const getRoomInfoDetail = await apiCall({
      url: endPoint.VIDEO_CALL_GET_ROOM_INFO,
      method: "post",
      data: JSON.stringify(data),
    });

    const decryptedDetails = getRoomInfoDetail;
    if (decryptedDetails.return_code === "fetched_data_successfully") {
      var staffId = decryptedDetails.result.staff.uuid;
      var candidateId = decryptedDetails.result.user.uuid;
      var saveRocording = decryptedDetails.result.saveRocording;
      localStorage.setItem(
        "staffFirstName",
        decryptedDetails?.result?.staff?.firstName
      );
      localStorage.setItem(
        "userFirstName",
        decryptedDetails?.result?.user?.firstName
      );
      if (uuid_value === staffId) {
        localStorage.setItem("userType", "staff");
      } else if (uuid_value === candidateId) {
        localStorage.setItem("userType", "candidate");
      }
      localStorage.setItem("saveRocording", saveRocording);
      // localStorage.setItem("userName", decryptedDetails.result.userName);
      // localStorage.setItem("type", decryptedDetails.result.type);
      localStorage.setItem("time", decryptedDetails.result.time);
      localStorage.setItem("verified", decryptedDetails.result.verified);

      const parts = decryptedDetails.result.time.split(";");
      if (parts.length === 2) {
        const times = parts[1];
        setTime(times);
      }
    } else {
      setError("please check your URL.");
    }
  };

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  // const room_id = getQueryParam('room_id');
  // const uuid = getQueryParam('uuid');

  // useEffect(() => {
  //   if (room_id)  localStorage.setItem("resourceful_video_call_id", room_id);
  //   if(uuid) localStorage.setItem("resourceful_video_call_room", uuid);
  // }, [room_id, uuid]);

  const handleClick = async () => {
    // if (!otp.trim()) {
    //   setError("Password is required.");
    //   return;
    // }
    let data = {
      room_id: room_id,
      uuid: uuid,
      password: otp,
    };
    console.log(data);
    const videoCallVerifyLink = await apiCall({
      url: endPoint.VIDEO_CALL_VERIFY_LINK,
      method: "post",
      data: JSON.stringify(data),
    });

    const decryptedDetails = videoCallVerifyLink;
    // if(decryptMessage(decryptedDetails.response.data).return_code === 'fetched_data_successfully') {
    //   localStorage.setItem("verified", 1);
    navigate(`/guidelines`);
    // } else {
    //   console.log(decryptMessage(decryptedDetails.response.data));
    //   setError(decryptMessage(decryptedDetails.response.data).message);
    // }
  };

  // const validateOtp = (otp) => {
  //   if (otp.trim() === "") {
  //     setError("OTP is required.");
  //     return false;
  //   }
  //   return true;
  // };

  const handleChange = (e) => {
    setOtp(e.target.value);
    if (error) setError("");
  };

  return (
    <>
      <div className='container_for_otp'>
        <div className='logo_container'>
          <img src={logo} alt='otp' />
          <h2 className='mt-6 text-[17px]'>{time ? time : ""}</h2>
          <h3 className='verification_font'>OTP Verification</h3>
        </div>
        <div className='Main_Container'>
          <div className='otp_bg_box lg:w-5/5 mx-auto'>
            <div className='input_container mx-auto'>
              <h2 className='text-center text-2xl font-semibold mb-8 main_heading_text w-[300px]'>
                Ready to Join?
              </h2>
              <h3 className='subText'>Enter Password</h3>
              <input
                type='text'
                name='otp'
                value={otp}
                onChange={handleChange}
                className='border border-[#5858df] w-full rounded h-[40px] pl-[10px]'
              ></input>
              {error && <p className='text-red-500 text-sm mt-2'>{error}</p>}
              <div className='text-center'>
                <button
                  className='submit_button'
                  type='submit'
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className='footer'>
        <p>
          <b>Note:</b> If you find difficulties joining this video call, we
          suggest you update your browser to the latest version. Get in touch
          with us if you still find difficulties joining the call.
        </p>
      </footer>
    </>
  );
};

export default VideoCallOtpScreen;
