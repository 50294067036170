import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PopupModal from "../PopupModal/PopupModal";
import Header from "../header";
import apiCall from "../../utils/axiosCall";
import style from "./GuideLinesStyle.css";
import { endPoint } from "../../utils/endPoint";

const GuideLines = ({ handle, setIsFullScreen }) => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const guideLinesRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [stream, setStream] = useState(null);
  const [error, setError] = useState(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (guideLinesRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = guideLinesRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setIsScrolledToBottom(true);
          setButtonDisable(true);
        }
      }
    };

    const guideLinesElement = guideLinesRef.current;
    if (guideLinesElement) {
      guideLinesElement.addEventListener("scroll", handleScroll);
      return () =>
        guideLinesElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const fetchData = async () => {
    const session_id = localStorage.getItem("session_id");

    try {
      let recordTimeData = {
        session_id,
        interview_timing_id: -1,
        end_time: new Date().toISOString(),
      };

      const response = await apiCall({
        url: endPoint.RECORD_INTERVIEW_TIME,
        method: "post",
        data: JSON.stringify(recordTimeData),
      });

      let result = response;

      if (result?.return_code === "fetched_interview_timing_success") {
        localStorage.setItem(
          "interview_timing_id",
          result?.result?.interview_timing_id
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async () => {
    const session_id = localStorage.getItem("session_id");

    let formData = { session_id };

    try {
      let response = await apiCall({
        url: endPoint.FETCH_INTERVIEW_INFO,
        method: "post",
        data: JSON.stringify(formData),
      });

      if (response.return_code === "interview_started_sucess") {
        localStorage.setItem(
          "interview_status",
          response.result.interview_status
        );
        localStorage.setItem(
          "candidate_first_name",
          response.result.candidate_first_name
        );
        localStorage.setItem(
          "candidate_last_name",
          response.result.candidate_last_name
        );

        await fetchData();

        const interviewStatus = localStorage.getItem("interview_status");
        if (interviewStatus !== "completed") {
          handle.enter();
          setIsFullScreen(true);
          await getPermission();
          navigate(`/interview/${uuid}`);
        } else {
          navigate(`/thank-you/`);
        }
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error starting interview:", error);
    }
  };

  const getPermission = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStream({ cameraStream: mediaStream, screenStream: null });
      setButtonDisable(true);
    } catch (error) {
      setIsChecked(false);
      setError(error);
    }
  };

  return (
    <>
      {/* <PopupModal /> */}
      <Header />
      <div className='Main_Container margin_top'>
        <div className='bg-gray-100 rounded-xl guide_line_bg_box lg:w-4/5 mx-auto'>
          <div className='container mx-auto'>
            <h2 className='text-center text-2xl font-semibold mb-8 main_heading_text'>
              Guidelines
            </h2>
            <div className='guide_line_container' ref={guideLinesRef}>
              <h3 className='text-left heading_text'>
                <b>Instructions</b>
              </h3>
              <p className='text-left paragraph_text'>
                Complete your video screening successfully and make a positive
                impression on the hiring manager. Please follow these tips and
                instructions:
              </p>
              <div className='text-left px-6'>
                <h5 className='text-left mt-2'>
                  <b>● Create a Distraction-Free Zone </b>
                </h5>
                <p className='text-left paragraph_text'>
                  Ensure you are in a quiet, distraction-free area and maintain
                  eye contact with the camera.
                </p>
                <h5 className='text-left mt-2'>
                  <b>● Stay on the Interview Screen</b>
                </h5>
                <p className='text-left paragraph_text'>
                  Do not switch tabs or refresh the screen during the interview.
                  Ensure you stay on this tab throughout the process.
                </p>
                <h5 className='text-left mt-2'>
                  <b> ● Be Aware of Monitoring</b>
                </h5>
                <p className='text-left paragraph_text'>
                  Your interview will be recorded and reviewed by the hiring
                  manager. Maintain a professional demeanor throughout to leave
                  a positive impression.
                </p>
                <h5 className='text-left mt-2'>
                  <b> ● Position Yourself Clearly</b>
                </h5>
                <p className='text-left paragraph_text'>
                  Ensure your upper body is clearly visible on camera. Avoid
                  unnecessary body, face, eye, or hand movements, and do not
                  move away from the camera to maximize your job opportunity.
                </p>
              </div>
              <h4 className='text-left mt-2 heading_text'>
                <b>Grant Permissions</b>
              </h4>
              <p className='text-left paragraph_text'>
                We require your permission to access the following tools for the
                interview. These permissions must remain enabled until the
                interview ends:
              </p>
              <ul className='text-left px-6 paragraph_text'>
                <li>● Enable Camera</li>
                <li>● Enable Microphone</li>
                <li>● Enable Audio</li>
              </ul>
            </div>
            <div className='text-center mt-6'>
              <button
                className={
                  buttonDisable ? "accept_button" : "disable_accept_button"
                }
                type='submit'
                onClick={handleSubmit}
                disabled={!buttonDisable}
              >
                Agree and Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuideLines;
