export const endPoint = {
  RECORD_INTERVIEW_TIME: "conversation/record-interview-time/",
  FETCH_INTERVIEW_INFO: "conversation/fetchinterviewinfo/",
  VERIFY_LINK: "conversation/verifylink/",
  VERIFY_OTP: "conversation/verifyotp/",
  CONVERSATION: "conversation/",
  GET_SESSION_INFO: "conversation/get-session-info/",
  FETCH_AUDIO: "conversation/fetch-audio/",
  FETCH_CURRENT_CONVERSATION: "conversation/fetch-current-conversation/",
  UPDATE_TIMESTAMP: "conversation/update-timestamp/",
  CONVERSATION_END: "conversation/end/",
  VIDEO_CALL_GET_ROOM_INFO: "videocall/get-room-info/",
  VIDEO_CALL_VERIFY_LINK: "videocall/verify-link/",
  AUTOSCRENN_INTERVIEW_TYPE: "autoscreen/get-interview-type/",
  AUTOSCREEN_FETCH_INTERVIEW_INFO: "autoscreen/fetchinterviewinfo/",
  AUTOSCREEN_GET_QUESTIONS: "autoscreen/fetch-question/",
  AUTOSCREEN_UPDATE_ANSWER: "autoscreen/update-answer/",
  AUTOSCREEN_END: "autoscreen/end-interview/",
  AUTOSCREEN_FETCH_CURRENT_CONVERSATION: "autoscreen/fetch-current-conversation/",
  EXTERNAL_INTERVIEW_ROOM_INFO: "external/get-room-info/",
  EXTERNAL_INTERVIEW_VERIFY_LINK: "external/verify-link/",
  EXTERNAL_INTERVIEW_UPDATE_ANSWER: "external/update-answer/",
};
