// Import dependency here
import axios from "axios";
import encryptMessage from './encrypt';
import decryptMessage from './decrypt';
// import { toast } from "react-toastify";

// Import redux store here
// import { store } from "../redux/store";

const apiCall = async ({
  url,
  method = "get",
  data = null,
  headers = { "Content-type": "application/json" },
  accessToken = "",
  showToaster = true,
}) => {
  try {
    // import base url from the env
    const Base_Url = process.env.REACT_APP_BASE_URL + url;
    // BODY ENCRYPT
    // Check if it's a login endpoint
    const isLoginEndpoint = url.includes("login");
    const isProduction = process.env.REACT_APP_NODE_ENV === "production";
    if(isProduction) {
      data = encryptMessage(data);
    }
    const response = await axios({
      method,
      url: Base_Url,
      data,
      headers: {
        ...headers,
      },
    });

    // if response status is success than show success toaster
    if (response.status === 200 || response.status === 201) {
      //   if (method === "post" || method === "put" || method === "delete") {
      // toast.success(response.data.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      //   }
      let responseData = response.data;
      if(isProduction) {
        responseData = decryptMessage(response.data);
      }
      return responseData;
    }
  } catch (error) {
    console.log("ERROR : ", error);
    // if (error?.response?.data?.return_code === "token_not_valid") {
    //   localStorage.removeItem("accessToken");
    //   localStorage.removeItem("refreshToken");
    //   localStorage.removeItem("isLogin");
    //   localStorage.removeItem("persist:root");
    //   store.dispatch({ type: "LOGOUT" });
    // }
    // if response status is fail than show error toaster
    // toast.error(error.response.data?.message, {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
    // console.error("API Error:", error);
    return error;
  }
};

export default apiCall;
