import React, { useEffect, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import Header from "../header";
import thankYou_Message from "../../assets/audio/thankyou.json";
import videoCall_thankyou from "../../assets/audio/video_call_thankyou.json";
const ThankYou = () => {
  const location = useLocation();
  //   useEffect(() => {
  //     // Set a timeout to close the tab after 5 seconds
  //     const timeoutId = setTimeout(() => {
  //       // Try to close the current tab
  //       window.close();
  //     }, 5000); // 5000 ms = 5 seconds

  //     // Cleanup timeout on component unmount
  //     return () => clearTimeout(timeoutId);
  //   }, []);
  useEffect(() => {
    if (window.performance) {
      console.log("performance", performance);
      if (performance.navigation.type === 1) {
        console.log("s");
      } else {
        let audioContent;
        if (location.state?.from === "VideoCall") {
          audioContent = videoCall_thankyou.audioContent;
        } else {
          audioContent = thankYou_Message.audioContent;
        }
        console.log("audioContent", audioContent);

        const byteCharacters = atob(audioContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "audio/mp3" });

        const audioUrl = URL.createObjectURL(blob);
        const audio = new Audio(audioUrl);
        audio.play();
      }
    }
  }, []);

  return (
    <div>
      <Header />
      <div className='video-preview rounded-2xl justify-center items-center flex lg:h-[93vh] h-[90vh] antialiased text-gray-800'>
        <div className='text-center py-4 lg:px-4'>
          <div
            className='flex lg:inline-flex border-t-4 border-lime-500 rounded-b text-lime-900 px-4 py-3 shadow-md'
            role='alert'
          >
            <div className='flex'>
              <p className='font-bold'>Thank You</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
